import { localizedF } from "js/includes/common/utils"

export const initialState = {
  administrative_session: false,

  // general
  full_screen_mode: true,
  color_depth: 32,
  use_all_monitors: false,
  connection_bar_in_full_screen: true,
  desktop_width: 1024,
  desktop_height: 768,
  keyboard_shortcuts_mode: "REMOTE_FULL_SCREEN",

  // local resources
  audio_playback: "LOCAL",
  audio_recording: false,
  drives_to_redirect: null,
  redirect_clipboard: false,
  redirect_com_ports: false,
  redirect_printers: false,
  redirect_smart_cards: false,

  // experience
  desktop_background: false,
  font_smoothing: false,
  desktop_composition: false,
  drag_shows_contents: false,
  menu_animation: false,
  visual_styles: false,
}

export const performanceSettings = {
  // general
  full_screen_mode: false,
  color_depth: 15,
  use_all_monitors: false,
  connection_bar_in_full_screen: true,
  desktop_width: 1680,
  desktop_height: 1050,
  keyboard_shortcuts_mode: "REMOTE_FULL_SCREEN",

  // local resources
  audio_playback: "NONE",
  audio_recording: false,
  drives_to_redirect: null,
  redirect_clipboard: true,
  redirect_com_ports: false,
  redirect_printers: false,
  redirect_smart_cards: false,

  // experience
  desktop_background: false,
  font_smoothing: false,
  desktop_composition: false,
  drag_shows_contents: false,
  menu_animation: false,
  visual_styles: false,
}

export const standardSettings = {
  // general
  full_screen_mode: false,
  color_depth: 24,
  use_all_monitors: false,
  connection_bar_in_full_screen: true,
  desktop_width: 1680,
  desktop_height: 1050,
  keyboard_shortcuts_mode: "REMOTE_FULL_SCREEN",

  // local resources
  audio_playback: "NONE",
  audio_recording: false,
  drives_to_redirect: null,
  redirect_clipboard: true,
  redirect_com_ports: false,
  redirect_printers: true,
  redirect_smart_cards: false,

  // experience
  desktop_background: true,
  font_smoothing: true,
  desktop_composition: false,
  drag_shows_contents: true,
  menu_animation: false,
  visual_styles: true,
}

export const multimediaSettings = {
  // general
  full_screen_mode: true,
  color_depth: 32,
  use_all_monitors: true,
  connection_bar_in_full_screen: true,
  desktop_width: 1680,
  desktop_height: 1050,
  keyboard_shortcuts_mode: "REMOTE_FULL_SCREEN",

  // local resources
  audio_playback: "LOCAL",
  audio_recording: true,
  drives_to_redirect: "*",
  redirect_clipboard: true,
  redirect_com_ports: true,
  redirect_printers: true,
  redirect_smart_cards: true,

  // experience
  desktop_background: true,
  font_smoothing: true,
  desktop_composition: true,
  drag_shows_contents: true,
  menu_animation: true,
  visual_styles: true,
}

const RDPProfiles = [
  {
    label: localizedF("Custom"),
    value: null,
    settings: initialState,
  },
  {
    label: localizedF("Performance"),
    value: "PERFORMANCE",
    settings: performanceSettings,
  },
  {
    label: localizedF("Standard"),
    value: "STANDARD",
    settings: standardSettings,
  },
  {
    label: localizedF("Multimedia"),
    value: "MULTIMEDIA",
    settings: multimediaSettings,
  },
]

export default RDPProfiles
