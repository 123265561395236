/** @jsxImportSource @emotion/react */
import { PureComponent } from "react"

import styled from "@emotion/styled"
import { colors } from "js/includes/common/theme"
import { localized } from "js/includes/common/utils"

const StyledFullScreenErrorPage = styled.div`
  background-color: ${colors.ninjaBlue};
  height: 100%;
`

const StyledNotFoundError = styled.div`
  color: ${colors.white};
  padding-top: 300px;
  text-align: center;
`

const StyledNumber = styled.div`
  font-size: 112px;
`

export default class BrandingErrorPage extends PureComponent {
  render() {
    return (
      <StyledFullScreenErrorPage>
        <StyledNotFoundError>
          <StyledNumber>404</StyledNumber>
          <div>
            {localized(
              "Sorry! The page you were looking for could not be found. If you feel you have reached this page in error, please contact your administrator.",
            )}
          </div>
        </StyledNotFoundError>
      </StyledFullScreenErrorPage>
    )
  }
}
