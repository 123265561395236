import React, { memo, useState } from "react"
import { prop, pluck, reduce, assoc } from "ramda"
import Select from "react-select"
import { Checkbox } from "js/includes/components/NinjaReactICheck"
import { localized, localizedF } from "js/includes/common/utils"
import RDPProfiles from "./RDPProfiles"

const colorOptions = [
  { label: localizedF("15 Bit"), value: 15 },
  { label: localizedF("16 Bit"), value: 16 },
  { label: localizedF("24 Bit"), value: 24 },
  { label: localizedF("32 Bit"), value: 32 },
]

const keyboardOptions = [
  { label: localizedF("On the local computer"), value: "LOCAL" },
  { label: localizedF("On the remote computer"), value: "REMOTE" },
  { label: localizedF("In full screen mode only"), value: "REMOTE_FULL_SCREEN" },
]

const audioPlaybackOptions = [
  { label: localizedF("Play sounds on the local computer"), value: "LOCAL" },
  { label: localizedF("Play sounds on the remote computer"), value: "REMOTE" },
  { label: localizedF("Do not play sounds"), value: "NONE" },
]

const audioRecordingOptions = [
  { label: localizedF("Do not capture audio from the local computer"), value: false },
  { label: localizedF("Capture audio from the local computer and send to the remote computer"), value: true },
]

const drivesToRedirectOptions = [
  { label: localizedF("All"), value: "*" },
  { label: localizedF("Dynamic drives"), value: "dynamicDrives" },
  { label: localizedF("None"), value: null },
]

const localRedirectOptions = [
  { label: localizedF("Clipboard"), key: "redirect_clipboard" },
  { label: localizedF("COM Ports"), key: "redirect_com_ports" },
  { label: localizedF("Printers"), key: "redirect_printers" },
  { label: localizedF("Smart Cards"), key: "redirect_smart_cards" },
]

const resolutionOptions = [
  { value: "640×480" },
  { value: "800×600" },
  { value: "1024×768" },
  { value: "1280×1024" },
  { value: "1680×1050" },
  { value: "1600×1200" },
]

export default memo(
  ({
    connection_settings,
    connection_profile,
    setConnectionProfileAndSettings,
    updateConnectionSettings,
    getValueFromOptions,
    getLocalizedLabel,
    loading,
    isTechnician,
  }) => {
    const {
      // general
      full_screen_mode,
      color_depth,
      use_all_monitors,
      connection_bar_in_full_screen,
      desktop_width,
      desktop_height,
      keyboard_shortcuts_mode,

      // local resources
      audio_playback,
      audio_recording,
      drives_to_redirect,

      // experience
      desktop_background,
      font_smoothing,
      desktop_composition,
      drag_shows_contents,
      menu_animation,
      visual_styles,
    } = connection_settings

    const [activeTab, setActiveTab] = useState("general")
    const setGeneralTab = () => setActiveTab("general")
    const setLocalResourcesTab = () => setActiveTab("localResources")
    const setExperienceTab = () => setActiveTab("experience")
    const localRedirectValues = localRedirectOptions.filter(({ key }) => connection_settings[key])

    return (
      <>
        <section>
          <div className="rdp-fields">
            <div className="rdp-field">
              <Select
                isDisabled={loading}
                value={getValueFromOptions(connection_profile, RDPProfiles)}
                options={RDPProfiles}
                onChange={setConnectionProfileAndSettings}
                getOptionLabel={getLocalizedLabel}
              />
            </div>
          </div>
        </section>

        <section className="tab-links-container">
          <ul className="tab-links">
            <li className={`tab-link ${activeTab === "general" ? "active" : ""}`} onClick={setGeneralTab}>
              <span>{localized("General")}</span>
            </li>
            <li className={`tab-link ${activeTab === "localResources" ? "active" : ""}`} onClick={setLocalResourcesTab}>
              <span>{localized("Local Resources")}</span>
            </li>
            {isTechnician && (
              <li className={`tab-link ${activeTab === "experience" ? "active" : ""}`} onClick={setExperienceTab}>
                <span>{localized("Experience")}</span>
              </li>
            )}
          </ul>
        </section>

        <div className={`tab ${activeTab === "general" ? "active" : ""}`}>
          <section>
            <label>{localized("Display")}</label>
            <div className="rdp-fields m-t-sm">
              <div className="rdp-field">
                <Checkbox
                  disabled={loading}
                  checked={full_screen_mode}
                  onChange={() => updateConnectionSettings(!full_screen_mode, "full_screen_mode")}
                  checkboxClass="icheckbox_square-blue"
                />
                {localized("Full screen mode")}
              </div>

              <div className="rdp-field">
                <Checkbox
                  disabled={loading}
                  checked={use_all_monitors}
                  onChange={() => updateConnectionSettings(!use_all_monitors, "use_all_monitors")}
                  checkboxClass="icheckbox_square-blue"
                />
                {localized("Use all monitors")}
              </div>

              <div className="rdp-field">
                <Checkbox
                  disabled={loading}
                  checked={connection_bar_in_full_screen}
                  onChange={() =>
                    updateConnectionSettings(!connection_bar_in_full_screen, "connection_bar_in_full_screen")
                  }
                  checkboxClass="icheckbox_square-blue"
                />
                {localized("Connection bar in full screen")}
              </div>

              <div className="rdp-field">
                <Select
                  value={{ value: `${desktop_width}×${desktop_height}` }}
                  options={resolutionOptions}
                  isDisabled={loading || full_screen_mode}
                  onChange={({ value }) => {
                    const [width, height] = value.split("×")
                    updateConnectionSettings({
                      desktop_width: parseInt(width, 10),
                      desktop_height: parseInt(height, 10),
                    })
                  }}
                  getOptionLabel={prop("value")}
                />
              </div>
            </div>
          </section>

          <section>
            <label>{localized("Colors")}</label>
            <div className="rdp-fields">
              <div className="rdp-field">
                <Select
                  isDisabled={loading}
                  value={getValueFromOptions(color_depth, colorOptions)}
                  options={colorOptions}
                  onChange={({ value }) => updateConnectionSettings(value, "color_depth")}
                  getOptionLabel={getLocalizedLabel}
                />
              </div>
            </div>
          </section>

          <section>
            <label>{localized("Shortcuts")}</label>
            <div className="rdp-fields">
              <div className="rdp-field">
                <Select
                  isDisabled={loading}
                  value={getValueFromOptions(keyboard_shortcuts_mode, keyboardOptions)}
                  options={keyboardOptions}
                  onChange={({ value }) => updateConnectionSettings(value, "keyboard_shortcuts_mode")}
                  getOptionLabel={getLocalizedLabel}
                />
              </div>
            </div>
          </section>
        </div>

        <div className={`tab ${activeTab === "localResources" ? "active" : ""}`}>
          <section>
            <label>{localized("Audio")}</label>
            <div className="rdp-fields">
              <div className="rdp-field">
                <Select
                  isDisabled={loading}
                  value={getValueFromOptions(audio_playback, audioPlaybackOptions)}
                  options={audioPlaybackOptions}
                  onChange={({ value }) => updateConnectionSettings(value, "audio_playback")}
                  getOptionLabel={getLocalizedLabel}
                />
              </div>
              <div className="rdp-field">
                <Select
                  isDisabled={loading}
                  value={getValueFromOptions(audio_recording, audioRecordingOptions)}
                  options={audioRecordingOptions}
                  onChange={({ value }) => updateConnectionSettings(value, "audio_recording")}
                  getOptionLabel={getLocalizedLabel}
                />
              </div>
            </div>
          </section>

          <section>
            <label>{localized("Local")}</label>
            <div className="rdp-fields">
              <Select
                isMulti
                closeMenuOnSelect={false}
                isDisabled={loading}
                value={localRedirectValues}
                options={localRedirectOptions}
                onChange={selection => {
                  const values = selection || []
                  const selectedKeys = pluck("key", values)
                  const keys = pluck("key", localRedirectOptions)

                  updateConnectionSettings(reduce((acc, key) => assoc(key, selectedKeys.includes(key), acc), {}, keys))
                }}
                getOptionLabel={getLocalizedLabel}
                getOptionValue={prop("key")}
              />
            </div>
          </section>

          <section>
            <label>{localized("Drives")}</label>
            <div className="rdp-fields">
              <Select
                isDisabled={loading}
                value={getValueFromOptions(drives_to_redirect, drivesToRedirectOptions)}
                options={drivesToRedirectOptions}
                onChange={({ value }) => updateConnectionSettings(value, "drives_to_redirect")}
                getOptionLabel={getLocalizedLabel}
              />
            </div>
          </section>
        </div>

        <div className={`tab ${activeTab === "experience" ? "active" : ""}`}>
          <section>
            <Checkbox
              disabled={loading}
              checked={desktop_background}
              onChange={() => updateConnectionSettings(!desktop_background, "desktop_background")}
              checkboxClass="icheckbox_square-blue"
            />
            {localized("Desktop background")}
          </section>

          <section>
            <Checkbox
              disabled={loading}
              checked={font_smoothing}
              onChange={() => updateConnectionSettings(!font_smoothing, "font_smoothing")}
              checkboxClass="icheckbox_square-blue"
            />
            {localized("Font smoothing")}
          </section>

          <section>
            <Checkbox
              disabled={loading}
              checked={desktop_composition}
              onChange={() => updateConnectionSettings(!desktop_composition, "desktop_composition")}
              checkboxClass="icheckbox_square-blue"
            />
            {localized("Desktop composition")}
          </section>

          <section>
            <Checkbox
              disabled={loading}
              checked={drag_shows_contents}
              onChange={() => updateConnectionSettings(!drag_shows_contents, "drag_shows_contents")}
              checkboxClass="icheckbox_square-blue"
            />
            {localized("Show window contents while dragging")}
          </section>

          <section>
            <Checkbox
              disabled={loading}
              checked={menu_animation}
              onChange={() => updateConnectionSettings(!menu_animation, "menu_animation")}
              checkboxClass="icheckbox_square-blue"
            />
            {localized("Menu animations")}
          </section>

          <section>
            <Checkbox
              disabled={loading}
              checked={visual_styles}
              onChange={() => updateConnectionSettings(!visual_styles, "visual_styles")}
              checkboxClass="icheckbox_square-blue"
            />
            {localized("Visual styles")}
          </section>
        </div>
      </>
    )
  },
)
