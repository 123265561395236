import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faWindows, faApple } from "@fortawesome/free-brands-svg-icons"
import { faNinjaControl } from "media/icons"
import tokens from "@ninjaone/tokens"
import { getOs, localized } from "js/includes/common/utils"
import { colors } from "js/includes/common/theme"
import Modal from "js/includes/components/Modal"
import { Box } from "js/includes/components/Styled"
import Loading from "js/includes/components/Loading"

function NinjaControlClientCommonModal({
  loading,
  launchUrl,
  unmount,
  showDownloadOptions,
  macClientDownloadUrl,
  windowsClientDownloadUrl,
  errorMessageToken,
}) {
  const os = getOs()
  const icon = os === "windows" ? faWindows : faApple
  return (
    <Modal
      overflow
      tallModal
      dataTitle
      title={
        <>
          <FontAwesomeIcon color={colors.ninjaBlue} icon={faNinjaControl} className="fa-lg m-r-xs" />
          Ninja Remote
        </>
      }
      close={unmount}
    >
      {loading ? (
        <Loading />
      ) : (
        <Box textAlign="left">
          {launchUrl && <iframe title="Ninja Remote" src={launchUrl} style={{ width: 0, height: 0, border: 0 }} />}
          <Box fontSize={tokens.typography.fontSize.body} marginBottom={tokens.spacing[5]}>
            {localized("Attempting to launch Ninja Remote connection...")}
          </Box>
          {errorMessageToken && <Box fontSize={tokens.typography.fontSize.body}>{localized(errorMessageToken)}</Box>}
          {showDownloadOptions && (
            <>
              <Box fontSize={tokens.typography.fontSize.body} marginBottom={tokens.spacing[3]}>
                {localized("To connect, you must have Ninja Remote installed.")}
              </Box>
              <Box display="inline-flex">
                <FontAwesomeIcon size="2x" className="device-icon m-r-sm" icon={icon} />
                {os === "osx" && (
                  <Box display="flex" alignItems="center">
                    <a href={macClientDownloadUrl} download rel="noopener noreferrer">
                      {localized("Download for Mac")}
                    </a>
                  </Box>
                )}
                {os === "windows" && (
                  <Box display="flex" alignItems="center">
                    <a className="m-r-md" href={windowsClientDownloadUrl.url_32} download rel="noopener noreferrer">
                      {localized("Download for Windows (32)")}
                    </a>
                    <a href={windowsClientDownloadUrl.url_64} download rel="noopener noreferrer">
                      {localized("Download for Windows (64)")}
                    </a>
                  </Box>
                )}
              </Box>
            </>
          )}
        </Box>
      )}
    </Modal>
  )
}

export default NinjaControlClientCommonModal
