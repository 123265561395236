const faSplashtop = {
  prefix: "fac",
  iconName: "splashtop",
  icon: [
    207,
    207,
    [],
    "0000",
    `M90.907,0.562c-24.191,3.333 -44.952,13.809 -61.619,30.857c-19.429,20 -29.333,44.286 -29.333,72.095c0,28.572 10,52.572 30.38,72.953c20.381,20.38 44.381,30.38 72.953,30.38c28.571,0 52.571,-10 72.952,-30.38c20.381,-20.381 30.381,-44.381 30.381,-72.953c0,-28.571 -10,-52.571 -30.381,-72.952c-19.143,-19.143 -41.714,-29.048 -68.952,-30.476c-5.238,-0.191 -12.572,0 -16.381,0.476Zm7.143,43.048c10.285,5.047 24.476,23.333 29.714,38.476c1.333,3.809 2.857,10.381 3.429,14.666c1.809,12.953 4.666,16.857 18.38,25.048c8.762,5.143 15.048,11.238 16.667,16c0.952,3.048 0.952,4.19 -0.476,7.048c-1.81,3.809 -6.191,6.19 -9.333,5.238c-2.477,-0.762 -7.048,-6.477 -10.096,-12.381c-4.952,-9.81 -12.476,-15.429 -22.571,-16.762c-11.429,-1.429 -17.905,3.143 -27.905,20.095c-9.905,16.667 -18.095,23.429 -30.285,25.048c-10.572,1.428 -23.048,-5.619 -28.572,-16c-3.238,-6.096 -3.143,-21.143 0.095,-27.81c5.238,-10.571 16.191,-19.238 28.096,-22.286c9.238,-2.381 26.19,-1.714 35.714,1.524c6.857,2.191 7.333,2.286 9.809,0.667c3.048,-2 3.238,-4.476 0.667,-9.429c-3.905,-7.619 -11.81,-12.571 -25.333,-16c-12.572,-3.238 -17.429,-7.142 -18.476,-15.047c-1.143,-8.857 2.38,-15.715 10.19,-19.334c5.143,-2.476 13.619,-1.904 20.286,1.239Zm67.523,39.047c6.381,3.238 8.667,8.762 4.953,12.191c-0.953,0.857 -4,2.095 -6.762,2.666c-6.191,1.334 -12.667,5.048 -14.857,8.476c-2.476,3.81 -3.619,2.191 -3.619,-4.761c0.095,-11.239 6.095,-19.715 14.095,-19.715c2.286,0 5.048,0.476 6.19,1.143Z`,
  ],
}

export default faSplashtop
