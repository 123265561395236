import React, { memo, useCallback, useEffect, useState } from "react"
import { pathEq } from "ramda"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faWindows, faApple } from "@fortawesome/free-brands-svg-icons"
import { faExclamationTriangle } from "@fortawesome/pro-solid-svg-icons"
import { faSplashtop } from "media/icons"
import Modal from "js/includes/components/Modal"
import ShowMessageDialog from "js/includes/components/MessageDialog"
import {
  localized,
  localizationKey,
  getOs,
  fetchJson,
  ninjaReportError,
  debugLog,
  isReportableWampException,
} from "js/includes/common/utils"
import { colors } from "js/includes/common/theme"

function getUrl(userType) {
  switch (userType) {
    case "TECHNICIAN":
      return "/splashtop/tokenbroker"
    case "END_USER":
      return "/end-user/connect/splashtop"
    default:
      throw new Error("Invalid user type")
  }
}

export default memo(({ node: { nodeId, splashtopSuuid: suuid }, userType, unmount }) => {
  const [splashtopComponent, setComponent] = useState({
    SPLASHTOP_CLIENT_WINDOWS: {
      downloadUrl: "https://resources.ninjarmm.com/Splashtop/splashtop_viewer_v1.0.4.0.exe",
    },
    SPLASHTOP_CLIENT_MAC: {
      downloadUrl: "https://resources.ninjarmm.com/Splashtop/splashtop_viewer.dmg",
    },
  })

  async function getSplashtopAgentStatus(nodeId) {
    try {
      const response = await window.wamp.call(nodeId, "status", [], {})
      return pathEq(["kwargs", "components", "Splashtop"], "enabled")(response)
    } catch (error) {
      if (isReportableWampException(error)) {
        ninjaReportError(error)
      }
      return false
    }
  }

  const requestSplashtopToken = useCallback(async () => {
    try {
      const request = {
        nodeId,
        suuid,
      }
      return await fetchJson(getUrl(userType), {
        options: {
          method: "POST",
          body: JSON.stringify(request),
        },
      })
    } catch (error) {
      ninjaReportError(error)
      return error
    }
  }, [nodeId, suuid, userType])

  const connect = useCallback(async () => {
    try {
      const splashtopAgentReady = await getSplashtopAgentStatus(nodeId)

      if (splashtopAgentReady) {
        const response = await requestSplashtopToken()

        if (response?.url) {
          const newWindow = window.open(response.url, "_parent")
          newWindow.opener = null
        } else {
          return ShowMessageDialog({
            icon: { icon: faExclamationTriangle, type: "critical" },
            title: localizationKey("Connection Failed"),
            message: () => `${localized("Could not obtain Splashtop connection:")} ${response.errorMessage}`,
            buttons: [{ id: "OK", label: localizationKey("OK") }],
          })
        }
      } else {
        return ShowMessageDialog({
          icon: { icon: faExclamationTriangle, type: "critical" },
          title: localizationKey("Splashtop is not ready"),
          message: localizationKey(
            "Splashtop is not ready for remote connections. Please try again after a minute or two.",
          ),
          buttons: [{ id: "OK", label: localizationKey("OK") }],
        })
      }
    } catch (error) {
      debugLog("error when attempting to setup Splashtop connection", error)
      return ShowMessageDialog({
        icon: { icon: faExclamationTriangle, type: "critical" },
        title: localizationKey("Connection Failed"),
        message: localizationKey("Could not obtain Splashtop connection: An unknown error occurred."),
        buttons: [{ id: "OK", label: localizationKey("OK") }],
      })
    }
  }, [nodeId, requestSplashtopToken])

  const setSplashtopComponent = useCallback(async () => {
    try {
      const components = ["SPLASHTOP_CLIENT_WINDOWS", "SPLASHTOP_CLIENT_MAC"]
      const splashtopClientComponent = await fetchJson("/webapp/division-components", {
        options: {
          method: "POST",
          body: JSON.stringify(components),
        },
      })
      setComponent(splashtopClientComponent)
    } catch (error) {
      ninjaReportError(error)
    }
  }, [])

  useEffect(() => {
    connect()
    setSplashtopComponent()
  }, [connect, setSplashtopComponent])

  return (
    <Modal
      overflow
      tallModal
      dataTitle
      title={
        <>
          <FontAwesomeIcon color={colors.green} icon={faSplashtop} className="fa-lg m-r-xs" />
          {localized("Splashtop")}
        </>
      }
      close={unmount}
    >
      <div className="text-align-left">
        <div className="splashtop-instructions m-b-md">{localized("Attempting to launch Splashtop connection...")}</div>
        <div className="splashtop-instructions">
          {localized(
            "To connect to a machine using the Splashtop for RMM integration, you must have Splashtop installed.",
          )}
        </div>
        <div className="splashtop-download-row">
          {getOs() === "windows" ? (
            <>
              <FontAwesomeIcon size="2x" className="device-icon m-r-sm" icon={faWindows} />
              <a href={splashtopComponent.SPLASHTOP_CLIENT_WINDOWS.downloadUrl} download rel="noopener noreferrer">
                {localized("Splashtop RMM For Windows")}
              </a>
            </>
          ) : (
            getOs() === "osx" && (
              <>
                <FontAwesomeIcon size="2x" className="device-icon m-r-sm" icon={faApple} />
                <a href={splashtopComponent.SPLASHTOP_CLIENT_MAC.downloadUrl} download rel="noopener noreferrer">
                  {localized("Splashtop RMM For Mac")}
                </a>
              </>
            )
          )}
        </div>
      </div>
    </Modal>
  )
})
