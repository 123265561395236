import { useRef } from "react"
import { ninjaReportError, reportErrorAndShowMessage } from "@ninjaone/webapp/src/js/includes/common/utils"
import { fetchJson } from "@ninjaone/webapp/src/js/includes/common/utils/fetch"
import styled from "@emotion/styled"
import tokens from "@ninjaone/tokens"
import { UnstyledButton } from "@ninjaone/components"

export const ssoRedirectZendesk = async () => {
  try {
    return await fetchJson("/zendesk/sso-token")
  } catch (error) {
    ninjaReportError(error)
  }
}

const StyledButton = styled(UnstyledButton)`
  width: 100%;
  text-align: left;
  margin: 0;
  display: flex;
  align-items: center;
  gap: ${({ increaseSpacing }) => (increaseSpacing ? tokens.spacing[3] : tokens.spacing[1])};
  font-size: ${tokens.typography.fontSize.body};
  line-height: ${tokens.typography.lineHeight};
  color: ${({ theme, increaseContrast }) => (increaseContrast ? theme.colorTextActionStrong : theme.colorTextAction)};

  &:hover,
  &:focus {
    color: ${({ theme, increaseContrast }) => (increaseContrast ? theme.colorTextActionStrong : theme.colorTextAction)};
  }

  &:focus-visible {
    outline-color: ${({ theme }) => theme.colorForegroundFocus};
  }

  &:hover {
    text-decoration: underline;
  }
`

const StyledForm = styled.form`
  display: inline-block;
`

const ZendeskSsoForm = ({ link, children, increaseContrast, increaseSpacing }) => {
  const formRef = useRef(null)
  const inputRef = useRef(null)

  const handleSubmit = async e => {
    e.preventDefault()

    try {
      const { jwt } = await ssoRedirectZendesk()
      if (jwt) {
        inputRef.current.value = jwt
        formRef.current.submit()
      }
    } catch (error) {
      reportErrorAndShowMessage(error)
    }
  }

  return (
    <StyledForm
      ref={formRef}
      target="_blank"
      action={`https://ninjarmm.zendesk.com/access/jwt?&return_to=${link}`}
      method="POST"
      data-testid="zendesk-sso-form"
      data-link={link}
    >
      <input ref={inputRef} type="hidden" name="jwt" data-testid="zendesk-jwt-input"></input>
      <StyledButton type="submit" onClick={handleSubmit} {...{ increaseContrast, increaseSpacing }}>
        {children}
      </StyledButton>
    </StyledForm>
  )
}

export default ZendeskSsoForm
