import { Dispatch, SetStateAction, useCallback, useState } from "react"

import { useMounted } from "./mounted"

export function useMountedState<T>(initialState: T | (() => T)): [T, Dispatch<SetStateAction<T>>] {
  const [value, _setValue] = useState<T>(initialState)
  const mounted = useMounted()

  const setValue = useCallback((newValue: SetStateAction<T>) => mounted.current && _setValue(newValue), [mounted])

  return [value, setValue]
}
