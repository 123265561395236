import styled from "@emotion/styled"
import { useTheme } from "@emotion/react"
import { sizer, sizerNumber, getTextSize, getLineHeight } from "@ninjaone/utils"
import { EmptyTableIcon } from "@ninjaone/icons"
import { localized, localizationKey } from "@ninjaone/webapp/src/js/includes/common/utils"
import SelectableKeyboardShortcutsTable from "@ninjaone/webapp/src/js/includes/components/SelectableKeyboardShortcutsTable"
import CustomizedTable from "@ninjaone/webapp/src/js/includes/components/Table"
import Text from "./Text"
import TitleGroup from "./TitleGroup"
import SearchBar from "./SearchBar"
import ButtonGroup from "./ButtonGroup"
import Dropdown from "./Dropdown"

const StyledTable = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  grid-row-gap: ${sizer(4)};

  .table-container {
    grid-row-gap: ${sizer(4)};

    .virtualized-table {
      min-height: ${sizer(67)};
    }

    .list-actions {
      height: 50px;
      font-size: ${getTextSize("md")};
      line-height: ${getLineHeight("md")};
      white-space: nowrap;
      margin: ${sizer(0, 3)};

      > button {
        height: ${getLineHeight("md")};
      }

      > * {
        margin: 0;
      }

      display: grid;
      grid-auto-flow: column;
      grid-auto-columns: min-content;
      grid-column-gap: ${sizer(4)};
      align-items: center;
    }
  }

  .virtualized-table {
    .ReactVirtualized__Table__headerRow {
      padding-top: 0;
      padding-bottom: 0;
      background-color: ${({ theme }) => theme.color.lightGray};
      border-bottom: 1px solid ${({ theme }) => theme.color.border} !important;
    }

    .ReactVirtualized__Table__rowColumn {
      color: ${({ theme }) => theme.color.text};
    }

    .table-cell,
    .ReactVirtualized__Table__headerColumn {
      .ReactVirtualized__Table__headerTruncatedText {
        color: ${({ theme }) => theme.color.text};
      }

      > * {
        font-size: ${getTextSize("sm")};
        line-height: ${getLineHeight("sm")};
      }

      &:not(.table-cell-checkbox) {
        padding: ${sizer(0, 1, 0, 0)};
      }
    }

    .active-row .table-cell.table-cell-checkbox {
      background-color: ${({ theme }) => theme.color.border} !important;
    }

    .not-selectable {
      .ReactVirtualized__Table__headerColumn,
      .ReactVirtualized__Table__rowColumn {
        padding: ${sizer(0, 6)};
        margin: 0;
      }
    }
  }
`

const StyledSearchAndActions = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: min-content;
  grid-column-gap: ${sizer(4)};
  justify-content: space-between;
`

export const StyledEmptyTable = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  grid-auto-flow: row;
  grid-auto-rows: min-content;
  grid-row-gap: ${sizer(2)};
  align-items: center;
  justify-content: center;
  justify-items: center;
  padding-top: ${sizer(28)};
`

export default function Table({
  titleGroup,
  disabled,
  searchPlaceholderToken,
  filterTabs,
  data,
  topActionButtons,
  topActionDropdown,
  noRowsToken,
  notSelectable,
  searchable,
  compact,
  ...rest
}) {
  const theme = useTheme()
  const searchPlaceholderText = localized(searchPlaceholderToken ?? localizationKey("Search"))
  const rowHeight = sizerNumber(compact ? 7 : 14)
  const headerHeight = sizerNumber(compact ? 7 : 11)

  function noRowsRenderer() {
    return (
      <StyledEmptyTable>
        <EmptyTableIcon
          colors={{ box: theme.color.veryLightGray, shadow: theme.color.lightGray, border: theme.color.gray }}
        />
        <Text token={noRowsToken} />
      </StyledEmptyTable>
    )
  }

  return (
    <StyledTable hasFilterTabs={!!filterTabs}>
      {titleGroup && <TitleGroup {...titleGroup} />}

      {notSelectable ? (
        <CustomizedTable
          {...{
            ...rest,
            className: "horizontal-tiles list-group not-selectable",
            rowClassName: "table-row no-hover",
            headerClassName: "table-header",
            rowHeight,
            headerHeight,
            list: data,
            noRowsRenderer,
          }}
        />
      ) : (
        <SelectableKeyboardShortcutsTable
          {...{
            ...rest,
            data,
            filterTabs,
            rowHeight,
            headerHeight,
            noRowsRenderer,
            searchable,
            ...(searchable
              ? {
                  searchRenderer({ onChange }) {
                    return (
                      <StyledSearchAndActions>
                        <SearchBar {...{ disabled, onChange }} placeholderText={searchPlaceholderText} />
                        {topActionButtons && <ButtonGroup buttons={topActionButtons} />}
                        {topActionDropdown && <Dropdown alignRight {...topActionDropdown} />}
                      </StyledSearchAndActions>
                    )
                  },
                }
              : {}),
          }}
        />
      )}
    </StyledTable>
  )
}
