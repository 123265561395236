import React, { useState, useRef } from "react"
import { connect } from "react-redux"
import qs from "qs"
import PropTypes from "prop-types"
import { cleanAppEnvironmentOrigin } from "js/includes/common/utils"
import Loading from "js/includes/components/Loading"
import { getSecuredFormsCdnRoute } from "js/includes/common/services/ninjaResourcesCdn"

const SecureFrame = function({ id, src, width, height, lang, additionalParams = {}, environment }) {
  const timestamp = useRef(Date.now())
  const [loader, setLoader] = useState(true)
  const hideLoader = () => setLoader(false)

  const query = qs.stringify(
    {
      ...additionalParams,
      ...(additionalParams?.origin ? { origin: cleanAppEnvironmentOrigin(additionalParams.origin) } : {}),
      lang,
      timestamp: timestamp.current,
    },
    {
      arrayFormat: "repeat",
    },
  )

  const isProductionReactBuild = process.env.NODE_ENV === "production"

  const frameSrc = `${
    isProductionReactBuild ? getSecuredFormsCdnRoute(environment) : process.env.PUBLIC_URL
  }/${src}?${query}`

  return (
    <>
      {loader && <Loading />}
      <iframe
        id={id}
        width={width || "100%"}
        height={height || "100%"}
        onLoad={hideLoader}
        src={frameSrc}
        title="Secure Frame"
        frameBorder="0"
      />
    </>
  )
}

SecureFrame.propTypes = {
  src: PropTypes.string.isRequired,
  width: PropTypes.string,
  height: PropTypes.string.isRequired,
  lang: PropTypes.string.isRequired,
  additionalParams: PropTypes.object,
  environment: PropTypes.string,
}

export default connect(state => ({
  environment: state.application.environment,
}))(SecureFrame)
