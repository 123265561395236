import { useRef, useEffect } from "react"

export function useIntervalWhen(callback, duration = 1000, when = true) {
  const savedRefCallback = useRef()

  useEffect(() => {
    savedRefCallback.current = callback
  })

  function innerCallback() {
    savedRefCallback.current && savedRefCallback.current()
  }

  useEffect(() => {
    if (when) {
      const interval = window.setInterval(innerCallback, duration)
      return () => {
        window.clearInterval(interval)
      }
    }
  }, [when, duration])
}
