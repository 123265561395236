import { ifElse, anyPass, includes, __, identity, always, test, replace, cond, T } from "ramda"

export const brandingDomains = [
  "rmmservice.com",
  "rmmservices.net",
  "rmmservice.eu",
  "rmmservice.com.au",
  "rmmservice.ca",
  "rmmservice.ninja",
]
export const resellerDomains = ["ninjarmm.itcloud.ca"]
export const ninjaHostNames = [
  "ninjarmm.com",
  "ninjaone.com",
  "engineering-env.ninja",
  "ninjastaging.ninja",
  ...brandingDomains,
  ...resellerDomains,
]

const ninjaLocalizationCdn = "https://localization.ninjarmm.com"

export function isProdEnvironment(env) {
  const productionEnvironments = ["us2", "app", "eu-central", "oc", "ca"]
  return productionEnvironments.includes(env)
}

export const getEnvRoute = ifElse(
  anyPass([
    env => isProdEnvironment(env),
    includes(__, ["qa-release", "qa-hotfix", "qa-hotfix-2", "qa-3", "qa4", "qa5", "qa6", "staging"]),
    test(/^dev-/),
  ]),
  identity,
  always("dev"),
)

export const getNinjaLocalizationCdnRoute = environment => {
  const route = getEnvRoute(environment)
  return `${ninjaLocalizationCdn}/${route}`
}

export const cleanAppEnvironmentOrigin = cond([
  [includes("-central"), replace("-central", "")],
  [T, identity],
])
