import { PureComponent } from "react"
import { FormControl } from "react-bootstrap"
import fastDeepEqual from "fast-deep-equal"
import PropTypes from "prop-types"
import { filter, propEq, reject, values } from "ramda"

import { Tabs } from "@ninjaone/components"

import { includes } from "js/includes/common/services/tranducedFiltering"
import { isEnterKey, isNotNilOrEmpty } from "js/includes/common/utils"
import { localizationKey, localized } from "js/includes/common/utils/ssrAndWebUtils"
import FilterOptions from "js/includes/components/Filters/FilterOptions"
import OutsideClickAlerter from "js/includes/components/OutsideClickAlerter"
import { Box, StyledSpan } from "js/includes/components/Styled"
import Table from "js/includes/components/Table"
import Tooltip from "js/includes/components/Tooltip"
import Checkbox from "./Checkbox"

const rejectDisabledRows = reject(propEq("disabled", true))

const searchByLocalizationMapper = {
  name: localizationKey("Name"),
  appName: localizationKey("Application Name"),
  biosSerialNumber: localizationKey("BIOS Serial Number"),
  clientName: localizationKey("Client Name"),
  description: localizationKey("Description"),
  deviceName: localizationKey("Device Name"),
  displayName: localizationKey("Display Name"),
  exclusions: localizationKey("Exclusions"),
  extension: localizationKey("Extension"),
  quickBooksName: localizationKey("QuickBooks Name"), // newly added from: searchable={["name", "quickBooksName"]} MappingTable.js
  ipAddress: localizationKey("IP Address"),
  id: localizationKey("ID"),
  lastLoggedInUser: localizationKey("Last Logged In User"),
  macAddress: localizationKey("MAC Address"),
  mappingStatus: localizationKey("Mapping Status"),
  mask: localizationKey("Mask"),
  osName: localizationKey("OS Name"),
  packageName: localizationKey("Package Name"),
  publicIp: localizationKey("Public IP"),
  releaseId: localizationKey("Release ID"),
  scheduleName: localizationKey("Schedule Name"),
  summary: localizationKey("Summary"),
  subject: localizationKey("Subject"),
  siteName: localizationKey("Site Name"),
  systemName: localizationKey("System Name"),
  targetType: localizationKey("Target Type"),
  wifiName: localizationKey("Wi-Fi Name (SSID)"),
}

class SelectableKeyboardShortcutsTable extends PureComponent {
  static propTypes = {
    data: PropTypes.array.isRequired,
    columns: PropTypes.func.isRequired,
    dataKey: PropTypes.string.isRequired,
    onCheck: PropTypes.func.isRequired,
    onSort: PropTypes.func,
    onSearch: PropTypes.func,
    onFilter: PropTypes.func,
    onMount: PropTypes.func,
    onUnmount: PropTypes.func,
    onScroll: PropTypes.func,
    onEnterPressed: PropTypes.func,
    onRowClick: PropTypes.func,
    onAction: PropTypes.func,
    onOutsideClick: PropTypes.func,
    onGetInitialState: PropTypes.func,
    filterRowData: PropTypes.func,
    getDefaultFilters: PropTypes.func,
    sort: PropTypes.func,
    noRowsRenderer: PropTypes.func,
    initialState: PropTypes.func,
    onHeaderClick: PropTypes.func,
    searchRenderer: PropTypes.func,
    actionsRenderer: PropTypes.func,
    rowHeight: PropTypes.number,
    headerHeight: PropTypes.number,
    headerClassName: PropTypes.string,
    filtersSearchBarSingleRow: PropTypes.bool,
    filtersContainerClassName: PropTypes.string,
    searchBarContainerClassName: PropTypes.string,
    rowClassName: PropTypes.string,
    className: PropTypes.string,
    sortBy: PropTypes.string,
    id: PropTypes.string,
    searchTooltipText: PropTypes.string,
    searchable: PropTypes.array,
    singleRowCheck: PropTypes.bool,
    preventClearSelection: PropTypes.bool,
    singleCheckOnRowClick: PropTypes.bool,
    actions: PropTypes.array,
    defaultSelectedRows: PropTypes.array,
    filterOptions: PropTypes.array,
    hideResultCount: PropTypes.bool,
    disableHeader: PropTypes.bool,
    disableSelectAll: PropTypes.bool,
    disabledCheckboxTooltip: PropTypes.string,
    disableSelectRow: PropTypes.bool,
    keepSelectionOnDataChange: PropTypes.bool,
    resultLabelRenderer: PropTypes.func,
    selectionLimit: PropTypes.number,
  }

  static defaultProps = {
    dataKey: "id",
    rowHeight: 45,
    headerHeight: 45,
    className: "horizontal-tiles list-group",
    rowClassName: "table-row",
    headerClassName: "table-header",
    filtersSearchBarSingleRow: false,
    defaultSelectedRows: [],
    actions: [],
    searchable: [],
    singleRowCheck: false,
    preventClearSelection: false,
    singleCheckOnRowClick: false,
    hideResultCount: false,
    disableHeader: false,
    disableSelectAll: false,
    disableSelectRow: false,
    keepSelectionOnDataChange: false,
    onAction: ({ action }, selected) => action(selected),
    onCheck: () => {},
    resultLabelRenderer: ({
      filteredData,
      hideResultCount,
      data,
      selected,
      singleRowCheck,
      showSelectedOnly,
      onShowSelectedOnly,
      id,
    }) => (
      <>
        {!filteredData && !hideResultCount && (
          <span className="m-r-sm">
            {data.length} {data.length === 1 ? localized("Result") : localized("Results")}
          </span>
        )}

        {!singleRowCheck && !!selected.length && selected.length === data.length && (
          <span className="m-r-sm">{localized("All {{selected}} Selected", { selected: data.length })}</span>
        )}

        {!singleRowCheck && !!selected.length && selected.length !== data.length && (
          <button
            key={id}
            type="button"
            className={`btn-link m-r-sm ${showSelectedOnly ? "text-underline" : ""}`}
            onClick={onShowSelectedOnly}
          >
            {selected.length} {localized("Selected")}
          </button>
        )}
      </>
    ),
  }

  constructor(props) {
    super(props)
    this.onCheckAll = this.onCheckAll.bind(this)
    this.onCheck = this.onCheck.bind(this)
    this.onSingleCheckRow = this.onSingleCheckRow.bind(this)
    this.onMultiRowCheck = this.onMultiRowCheck.bind(this)
    this.onKeyDownSelect = this.onKeyDownSelect.bind(this)
    this.onShowSelectedOnly = this.onShowSelectedOnly.bind(this)
    this.getColumns = this.getColumns.bind(this)
    this.getDataList = this.getDataList.bind(this)
    this.getDefaultState = this.getDefaultState.bind(this)
    this.getInitialState = this.getInitialState.bind(this)
    this.setDefaultSelectedRows = this.setDefaultSelectedRows.bind(this)
    this.handleRowClick = this.handleRowClick.bind(this)
    this.rowRenderer = this.rowRenderer.bind(this)
    this.createRowsDataMap = this.createRowsDataMap.bind(this)
    this.addAllSelected = this.addAllSelected.bind(this)
    this.removeAllSelected = this.removeAllSelected.bind(this)
    this.clearSelection = this.clearSelection.bind(this)
    this.dispatchCallback = this.dispatchCallback.bind(this)
    this.onSearchChange = this.onSearchChange.bind(this)
    this.onFilterChange = this.onFilterChange.bind(this)
    this.filterData = this.filterData.bind(this)
    this.autoScrollIntoView = this.autoScrollIntoView.bind(this)
  }

  list: []
  rowRefs = {}
  rowsDataMap = {}
  state = this.getDefaultState()

  getDefaultState(initialState = {}) {
    return {
      loading: false,
      activeRow: 0,
      mode: "neutral",
      filterText: "",
      filteredData: null,
      filterTab: "all",
      showSelectedOnly: false,
      shouldContentOverlay: false,
      selected: { rows: {}, length: 0 },
      ...initialState,
    }
  }

  async getInitialState() {
    const {
      props: { onGetInitialState },
      state,
    } = this

    if (onGetInitialState) {
      const initialState = await onGetInitialState(state)
      this._isMounted && this.setState(this.getDefaultState(initialState))
    }
  }

  filterInitialData() {
    const filteredData = this.filterData(this.state.filterText, this.props.filters)
    this.onFilterChange(filteredData, this.props.filters)
  }

  async componentDidUpdate(prevProps) {
    const { keepSelectionOnDataChange, data } = this.props
    if (prevProps.data !== data) {
      await this.getInitialState()
      this.createRowsDataMap()
      !keepSelectionOnDataChange && this.setDefaultSelectedRows()
      this.filterInitialData()
    }
  }

  async componentDidMount() {
    const { keepSelectionOnDataChange } = this.props
    this._isMounted = true
    document.addEventListener("keydown", this.onKeyDownSelect)

    await this.getInitialState()
    this.createRowsDataMap()
    !keepSelectionOnDataChange && this.setDefaultSelectedRows()
    this.filterInitialData()
  }

  componentWillUnmount() {
    this._isMounted = false
    document.removeEventListener("keydown", this.onKeyDownSelect)

    if (this.props.onUnmount) {
      this.props.onUnmount(this.state)
    }
  }

  getDataList() {
    const {
      state: { selected, filteredData, showSelectedOnly },
      props: { data },
    } = this

    if (showSelectedOnly && selected.length) {
      return Object.values(selected.rows)
    }

    return filteredData || data
  }

  createRowsDataMap() {
    const { data, dataKey } = this.props

    this.rowsDataMap = data.reduce((acc, rowData) => {
      acc[rowData[dataKey]] = rowData
      return acc
    }, {})
  }

  setDefaultSelectedRows() {
    const { dataKey, defaultSelectedRows, onCheck } = this.props

    const selected = {
      rows: {},
      length: 0,
    }

    defaultSelectedRows.forEach(dataKey => {
      if (this.rowsDataMap[dataKey]) {
        selected.rows[dataKey] = this.rowsDataMap[dataKey]
        selected.length++
      }
    })

    values(this.state.selected.rows).forEach(item => {
      if (this.rowsDataMap[item[dataKey]] && !selected.rows[item[dataKey]]) {
        selected.rows[item[dataKey]] = this.rowsDataMap[item[dataKey]]
        selected.length++
      }
    })

    this._isMounted &&
      this.setState({ selected }, () => {
        onCheck({ selected: this.state.selected })
      })
  }

  clearSelection() {
    const { onCheck } = this.props

    this._isMounted &&
      this.setState(
        {
          activeRow: 0,
          mode: "neutral",
          selected: {
            rows: {},
            length: 0,
          },
          showSelectedOnly: false,
        },
        () => {
          onCheck({ selected: this.state.selected })
        },
      )
  }

  onKeyDownSelect(event) {
    const {
      props: { dataKey, onEnterPressed, onCheck, singleRowCheck },
      state: { mode, activeRow, showSelectedOnly },
      list,
    } = this

    if (event.target.tagName === "INPUT" || showSelectedOnly) {
      return
    }

    const selected = { ...this.state.selected }

    if (!list.length) return

    if (event.keyCode === 13) {
      return (
        onEnterPressed &&
        onEnterPressed({
          rowData: list[activeRow],
          index: activeRow,
          event,
          mode,
        })
      )
    }

    if (event.keyCode === 27) {
      return this.clearSelection()
    }

    if (!singleRowCheck && event.shiftKey && event.keyCode === 38) {
      event.preventDefault()

      const prevRow = mode !== "select" ? activeRow : Math.max(activeRow - 1, 0)
      const nextRow = mode !== "select" ? activeRow : Math.min(activeRow + 1, list.length - 1)

      if (!selected.rows[list[prevRow][dataKey]]) {
        selected.rows[list[prevRow][dataKey]] = list[prevRow]
        selected.length++
      } else if (activeRow === list.length - 1 || (activeRow && !selected.rows[list[nextRow][dataKey]])) {
        delete selected.rows[list[activeRow][dataKey]]
        selected.length--
      }

      return this.setState(
        {
          activeRow: prevRow,
          mode: "select",
          selected,
        },
        () => {
          onCheck({ selected: this.state.selected })
          this.autoScrollIntoView({ rowData: list[prevRow] })
        },
      )
    }

    if (!singleRowCheck && event.shiftKey && event.keyCode === 40) {
      event.preventDefault()

      const nextRow = mode !== "select" ? activeRow : Math.min(activeRow + 1, list.length - 1)
      const prevRow = mode !== "select" ? activeRow : Math.max(activeRow - 1, 0)

      if (!selected.rows[list[nextRow][dataKey]]) {
        selected.rows[list[nextRow][dataKey]] = list[nextRow]
        selected.length++
      } else if (!activeRow || (activeRow !== list.length - 1 && !selected.rows[list[prevRow][dataKey]])) {
        delete selected.rows[list[activeRow][dataKey]]
        selected.length--
      }

      return this.setState(
        {
          activeRow: nextRow,
          mode: "select",
          selected,
        },
        () => {
          onCheck({ selected: this.state.selected })
          this.autoScrollIntoView({ rowData: list[nextRow] })
        },
      )
    }

    if (event.keyCode === 38) {
      event.preventDefault()
      const prevRow = mode !== "select" ? activeRow : activeRow - 1 >= 0 ? activeRow - 1 : 0

      selected.rows = {}
      selected.rows[list[prevRow][dataKey]] = list[prevRow]
      selected.length = 1

      return this.setState(
        {
          mode: "select",
          activeRow: prevRow,
          selected,
        },
        () => {
          onCheck({
            selected: this.state.selected,
            rowData: list[prevRow],
            checked: true,
          })
          this.autoScrollIntoView({ rowData: list[prevRow] })
        },
      )
    }

    if (event.keyCode === 40) {
      event.preventDefault()
      const nextRow = mode !== "select" ? activeRow : activeRow + 1 < list.length ? activeRow + 1 : list.length - 1

      selected.rows = {}
      selected.rows[list[nextRow][dataKey]] = list[nextRow]
      selected.length = 1

      return this.setState(
        {
          mode: "select",
          activeRow: nextRow,
          selected,
        },
        () => {
          onCheck({
            selected: this.state.selected,
            rowData: list[nextRow],
            checked: true,
          })
          this.autoScrollIntoView({ rowData: list[nextRow] })
        },
      )
    }
  }

  onShowSelectedOnly() {
    const { data } = this.props
    const { selected } = this.state

    if (selected.length === data.length) return

    this.setState(({ showSelectedOnly }) => ({
      showSelectedOnly: !showSelectedOnly,
    }))
  }

  removeAllSelected(list) {
    const selected = { ...this.state.selected }
    const { dataKey } = this.props

    list.forEach(item => {
      if (selected.rows[item[dataKey]]) {
        delete selected.rows[item[dataKey]]
        selected.length--
      }
    })

    return selected
  }

  addAllSelected(list) {
    const selected = { ...this.state.selected }
    const { dataKey } = this.props

    list.forEach(item => {
      if (!selected.rows[item[dataKey]]) {
        selected.rows[item[dataKey]] = item
        selected.length++
      }
    })

    return selected
  }

  onCheckAll(allChecked) {
    const {
      props: { singleRowCheck, onCheck },
      list,
    } = this

    if (singleRowCheck) return

    const checkableRows = rejectDisabledRows(list)
    const selected = allChecked ? this.removeAllSelected(checkableRows) : this.addAllSelected(checkableRows)

    this.setState(
      ({ showSelectedOnly }) => ({
        activeRow: 0,
        mode: "neutral",
        selected,
        showSelectedOnly: selected.length ? showSelectedOnly : false,
      }),
      () => {
        onCheck({ selected })
      },
    )
  }

  onSingleCheckRow(rowData, index) {
    const { selected } = this.state
    const { dataKey, onCheck, preventClearSelection } = this.props

    if (selected.rows[rowData[[dataKey]]]) {
      if (!preventClearSelection) {
        this.clearSelection()
      }
    } else {
      this.setState(
        {
          mode: "select",
          activeRow: index,
          selected: {
            rows: {
              [rowData[dataKey]]: rowData,
            },
            length: 1,
          },
        },
        () => {
          onCheck({
            rowData,
            checked: true,
            selected: this.state.selected,
          })
        },
      )
    }
  }

  onCheck(rowData, activeRow, event, isChecked) {
    const { singleRowCheck } = this.props

    if (rowData.disabled) {
      return
    }

    if (singleRowCheck) {
      this.onSingleCheckRow(rowData, activeRow)
    } else {
      this.onMultiRowCheck(rowData, activeRow, event, isChecked)
    }
  }

  onMultiRowCheck(rowData, activeRow, event, isChecked) {
    event.persist()

    const {
      props: { dataKey, onCheck },
      state: { activeRow: currentActiveRow },
      list,
    } = this

    const selected = { ...this.state.selected }
    const checked = isChecked

    let mode = "select"

    if (event && event.nativeEvent.shiftKey) {
      const dataSection =
        currentActiveRow <= activeRow
          ? list.slice(currentActiveRow, activeRow + 1)
          : list.slice(activeRow, currentActiveRow + 1)

      dataSection.forEach(row => {
        if (!selected.rows[row[dataKey]]) {
          selected.rows[row[dataKey]] = row
          selected.length++
        }
      })
    } else {
      if (selected.rows[rowData[dataKey]]) {
        delete selected.rows[rowData[dataKey]]
        selected.length--
      } else {
        selected.rows[rowData[dataKey]] = rowData
        selected.length++
      }

      if (!checked && !selected.length) {
        mode = "neutral"
      }
    }

    this.setState(
      ({ showSelectedOnly }) => ({
        mode,
        selected,
        activeRow: selected.length ? activeRow : 0,
        showSelectedOnly: selected.length ? showSelectedOnly : false,
      }),
      () => {
        onCheck({
          rowData,
          selected,
          checked,
          event,
        })
      },
    )
  }

  handleRowClick(rowData, index, event) {
    const {
      state: { mode, selected },
      props: { singleCheckOnRowClick, onRowClick, singleRowCheck },
      onCheck,
    } = this

    if (singleCheckOnRowClick || singleRowCheck) {
      this.onSingleCheckRow(rowData, index)
    }

    if (!!selected.length && index !== undefined) {
      return onCheck(rowData, index, event)
    }

    this.autoScrollIntoView({ rowData })

    onRowClick &&
      onRowClick({
        rowData,
        index,
        mode,
        event,
      })
  }

  autoScrollIntoView({ rowData }) {
    const {
      props: { dataKey },
      rowRefs,
    } = this
    rowRefs[rowData[dataKey]] &&
      rowRefs[rowData[dataKey]].scrollIntoView({ behavior: "smooth", block: "nearest", inline: "nearest" })
  }

  rowRenderer({ className, columns, index, key, rowData, style }) {
    const {
      props: { dataKey },
      state: { mode, activeRow, selected },
      rowRefs,
      handleRowClick,
    } = this

    const rowProps = { "aria-rowindex": index + 1 }

    rowProps["aria-label"] = "row"
    rowProps.tabIndex = 0
    rowProps.onClick = event => {
      handleRowClick(rowData, index, event)
    }

    return (
      <div
        ref={r => (rowRefs[rowData[dataKey]] = r)}
        {...rowProps}
        key={key}
        role="row"
        style={style}
        className={`${className} ${!!selected.rows[rowData[dataKey]] ? "selected checked" : ""} ${
          mode !== "neutral" && activeRow === index ? "active-row" : ""
        } ${rowData.disabled ? "no-hover disabled-row" : ""}`}
      >
        {columns}
      </div>
    )
  }

  getColumns(list, allChecked) {
    const {
      props: {
        columns,
        dataKey,
        singleRowCheck,
        disableSelectAll,
        disabledCheckboxTooltip,
        disableSelectRow,
        selectionLimit,
      },
      state: { selected },
      onCheckAll,
      onCheck,
    } = this

    return [
      ...(singleRowCheck
        ? []
        : [
            {
              dataKey: "checkbox",
              width: 37,
              className: "table-cell table-cell-checkbox",
              headerClassName: "table-cell-checkbox",
              headerRenderer: ({ onChange }) =>
                disableSelectAll || isNotNilOrEmpty(selectionLimit) ? null : (
                  <Checkbox checked={allChecked} onChange={() => onCheckAll(allChecked)} disabled={!list.length} />
                ),
              cellRenderer: ({ rowData, rowIndex }) => {
                const checked = !!selected.rows[rowData[dataKey]]
                const disabled = rowData.disabled || disableSelectRow || (!checked && selected.length >= selectionLimit)

                return disabled && disabledCheckboxTooltip ? (
                  <Tooltip output={disabledCheckboxTooltip}>
                    <Checkbox checked disabled />
                  </Tooltip>
                ) : (
                  <Checkbox
                    checked={checked}
                    disabled={disabled}
                    onChange={event => onCheck(rowData, rowIndex, event, event.target.checked)}
                  />
                )
              },
            },
          ]),
      ...columns(this),
    ]
  }

  dispatchCallback({ type }) {
    if (!this._isMounted) return

    switch (type) {
      case "CLEAR_SELECTION":
        this.clearSelection()
        return
      case "SELECT_ALL":
        this.onCheckAll()
        return
      default:
        return
    }
  }

  onFilterChange(filteredData, _filters) {
    const { onFilter } = this.props

    this._isMounted &&
      this.setState(
        {
          filteredData,
          mode: "neutral",
        },
        () => {
          onFilter && onFilter(_filters)
        },
      )
  }

  onSearchChange(filterText, filteredData) {
    const { onSearch } = this.props

    this._isMounted &&
      this.setState(
        {
          filterText,
          filteredData,
          mode: "neutral",
        },
        () => {
          onSearch && onSearch([filterText, filteredData])
        },
      )
  }

  filterData(str = "", filters) {
    const { filterTab } = this.state
    const { data, filterOptions, filterRowData, getDefaultFilters, searchable, filterTabs, filterRenderer } = this.props
    const lowercasedValue = str.trim().toLowerCase()

    const isSearching = searchable && lowercasedValue
    const isFiltering =
      filters && filterOptions && filterRowData && getDefaultFilters && !fastDeepEqual(filters, getDefaultFilters())
    const isFilteringTab = filters && filterTabs && filterTab !== "all"
    const isFilteringCustom = filters && filterRenderer && filterRowData

    if (!isSearching && !isFiltering && !isFilteringTab && !isFilteringCustom) {
      return null
    }

    return filter(d => {
      const isFilterableRow =
        isFiltering || isFilteringCustom
          ? filterRowData?.(d, filters)
          : isFilteringTab
          ? filters[filterTab]?.find(row => fastDeepEqual(d, row))
          : true
      const isSearchableRow = isSearching ? searchable.some(key => includes(d, key, lowercasedValue)) : true
      return isFilterableRow && isSearchableRow
    }, data)
  }

  render() {
    const {
      state: { filterText, filteredData, showSelectedOnly, selected, loading },
      props: {
        id,
        sort,
        sortBy,
        sortDirection,
        noRowsRenderer,
        rowHeight,
        headerHeight,
        onSort,
        onScroll,
        onRowRightClick,
        onRowMouseOut,
        onRowMouseOver,
        onAction,
        onOutsideClick,
        singleRowCheck,
        className,
        rowClassName,
        gridClassName,
        headerClassName,
        filtersSearchBarSingleRow,
        filtersContainerClassName,
        searchBarContainerClassName,
        dataKey,
        actions,
        data,
        onRowsRendered,
        hideResultCount,
        disableHeader,
        searchable,
        searchTooltipText,
        filters,
        filterRowData,
        filterOptions,
        getDefaultFilters,
        customFilterActions,
        searchRenderer,
        actionsRenderer,
        disableActionList,
        filterTabs,
        filterRenderer,
        onHeaderClick,
        resultLabelRenderer,
        customTableRenderer,
      },
      getColumns,
      getDataList,
      rowRenderer,
      dispatchCallback,
      onShowSelectedOnly,
      onFilterChange,
      onSearchChange,
      filterData,
    } = this

    const list = getDataList()
    const defaultFilters = getDefaultFilters && getDefaultFilters()
    const checkableRows = rejectDisabledRows(list)
    const allChecked = checkableRows.length > 0 && !checkableRows.some(row => !selected.rows[row[dataKey]])
    const columns = getColumns(list, allChecked)
    const outputTokenizedText = !searchTooltipText
      ? `${localized("Search by")}: ${searchable
          .map(key => localized(searchByLocalizationMapper[key] ?? key))
          .join(", ")}`
      : ""

    const tableProps = {
      id,
      list,
      columns,
      sort,
      sortBy,
      sortDirection,
      rowRenderer,
      noRowsRenderer,
      onSort,
      onScroll,
      onRowRightClick,
      onRowMouseOut,
      onRowMouseOver,
      rowHeight,
      headerHeight,
      className,
      rowClassName,
      gridClassName,
      headerClassName,
      onRowsRendered,
      disableHeader,
      onHeaderClick,
      onSortedList: sortedList => (this.list = sortedList),
    }

    this.list = list

    return (
      <OutsideClickAlerter
        className="flex-full display-flex flex-column table-container"
        handleClickOutside={event => {
          if (onOutsideClick) {
            onOutsideClick(selected, { dispatchCallback, event })
          }
        }}
      >
        <>
          {loading && <div className="data-fetching-overlay" />}

          <Box display="flex" flexDirection={filtersSearchBarSingleRow ? "row" : "column"}>
            {filterRenderer ? (
              <Box marginRight="16px">
                {filterRenderer({
                  handleFilter: _filters => {
                    onFilterChange(filterData(filterText, _filters), _filters)
                  },
                })}
              </Box>
            ) : (
              !!filters &&
              !!filterOptions &&
              !!filterRowData &&
              !!getDefaultFilters && (
                <Box
                  display="flex"
                  width={filtersSearchBarSingleRow ? "auto" : "100%"}
                  className={filtersContainerClassName}
                >
                  <FilterOptions
                    {...{
                      filters,
                      filterOptions,
                      defaultFilters,
                      onClearFilters: () => {
                        const filteredData = filterData(filterText, defaultFilters)
                        onFilterChange(filteredData, defaultFilters)
                      },
                      onFilterChange: updatedFilters => {
                        const _filters = {
                          ...filters,
                          ...updatedFilters,
                        }
                        const filteredData = filterData(filterText, _filters)

                        onFilterChange(filteredData, _filters)
                      },
                      className: "m-b-xs",
                    }}
                  />
                  {customFilterActions && customFilterActions()}
                </Box>
              )
            )}

            {searchRenderer
              ? searchRenderer({
                  ...this.state,
                  onFilterChange,
                  onChange: event => onSearchChange(event.target.value, filterData(event.target.value, filters)),
                })
              : !!searchable.length && (
                  <div className={`position-relative m-b-sm ${searchBarContainerClassName}`}>
                    <div className="position-relative display-flex align-items-center">
                      <StyledSpan position="absolute" right={10}>
                        <Tooltip token={searchTooltipText} output={outputTokenizedText} />
                      </StyledSpan>

                      <FormControl
                        {...{
                          disabled: showSelectedOnly,
                          value: filterText,
                          placeholder: localized("Search"),
                          style: {
                            width: "100%",
                            paddingRight: "35px",
                          },
                          onKeyDown: event => {
                            if (isEnterKey(event)) {
                              event.preventDefault()
                            }
                          },
                          onChange: ({ target: { value } }) => {
                            onSearchChange(value, filterData(value, filters))
                          },
                        }}
                      />
                    </div>
                  </div>
                )}
          </Box>

          {filterTabs && (
            <Tabs
              hidePanel
              tabs={[
                { labelToken: localizationKey("All"), value: "all", count: data?.length ?? 0 },
                ...filterTabs.map(tab => ({
                  ...tab,
                  count: filters?.[tab.value]?.length ?? 0,
                })),
              ]}
              onChange={({ value }) => {
                this.setState({ filterTab: value }, () => {
                  onFilterChange(filterData(filterText, filters))
                })
              }}
            />
          )}

          <div
            className={`m-l-xs ${disableActionList ? "m-b-xs" : "list-actions m-b-sm"} ${
              disableHeader ? "m-b-xs" : ""
            }`}
          >
            {!!filteredData && !hideResultCount && (
              <span className="m-r-sm">
                {list.length
                  ? localized("{{results}} Result(s) in {{total}}", { results: list.length, total: data.length })
                  : localized("No results match")}
              </span>
            )}

            {resultLabelRenderer({
              filteredData,
              hideResultCount,
              data,
              selected,
              singleRowCheck,
              showSelectedOnly,
              onShowSelectedOnly,
              id,
            })}

            {actions.map(action => {
              const { id, actionRenderer, token, label = () => null, className = "", position = "left" } = action
              const onClick = async event => {
                this.setState({ loading: true })
                await onAction(action, selected, { dispatchCallback, event })
                this._isMounted && this.setState({ loading: false })
              }

              return (
                actionRenderer?.({
                  action,
                  onClick,
                  filterText,
                  filteredData,
                  data,
                  list,
                  selected,
                  dispatchCallback,
                }) ?? (
                  <button
                    key={id}
                    type="button"
                    className={`btn-link ${position === "right" ? "float-right m-l-sm" : "m-r-sm"} ${className}`}
                    disabled={loading}
                    onClick={onClick}
                  >
                    {token ? localized(token) : label()}
                  </button>
                )
              )
            })}

            {actionsRenderer && actionsRenderer({ loading, selected, dispatchCallback })}
          </div>

          {customTableRenderer?.(tableProps) ?? <Table {...tableProps} />}
        </>
      </OutsideClickAlerter>
    )
  }
}

export default SelectableKeyboardShortcutsTable
