import React, { PureComponent } from "react"
import { assoc, prop, propEq, mergeRight, equals, dissoc } from "ramda"
import Select from "react-select"
import { faExclamation } from "@fortawesome/pro-solid-svg-icons"
import { Checkbox } from "js/includes/components/NinjaReactICheck"
import {
  localized,
  localizationKey,
  localizedF,
  getOs,
  fetchJson,
  debugLog,
  ninjaReportError,
  isReportableWampException,
} from "js/includes/common/utils"
import Modal from "js/includes/components/Modal"
import Portal from "js/includes/components/Portal"
import Tooltip from "js/includes/components/Tooltip"
import Loading from "js/includes/components/Loading"
import RDPConnectionSettings from "./RDPConnectionSettings"
import {
  initialState as connection_settings,
  performanceSettings,
  standardSettings,
  multimediaSettings,
} from "./RDPProfiles"
import ShowMessageDialog from "js/includes/components/MessageDialog"

const RDPErrors = {
  no_rdp_service_on_target: localizedF("RDP is disabled on target, please enable RDP on target and try again."),
  tunnel_setup_failed: localizedF("Error during tunnel setup, please make sure the target is RDP compatible."),
  maximum_rdp_connections_exceeded: localizedF(
    "Maximum concurrent RDP connections exceeded, allow at least one of the current connections to close before trying again.",
  ),
  wrong_tunnel_setup_parameters: localizedF(
    "The RDP set up is not correct, please review the set up parameters and try again.",
  ),
  njlink_download_fail: localizedF("The link download has failed, please try again."),
  tunneling_rdp_failed_to_initiate_connection: localizedF(
    "Communication with the device has failed, please try bringing the device back online and try again.",
  ),
}

const gatewayRegions = [
  { label: localizedF("Auto"), value: "auto" },
  { label: localizedF("Oregon, USA"), value: "us-west-2" },
  { label: localizedF("Ohio, USA"), value: "us-east-2" },
  { label: localizedF("London, UK"), value: "eu-west-2" },
  { label: localizedF("Frankfurt, Germany"), value: "eu-central-1" },
  { label: localizedF("Sydney, Australia"), value: "ap-southeast-2" },
]

function getUrl(userType) {
  switch (userType) {
    case "TECHNICIAN":
      return "/rdp/tunnel/request"
    case "END_USER":
      return "/end-user/connect/rdp/request"
    default:
      throw new Error("Invalid user type")
  }
}

export default class RDPConnectModal extends PureComponent {
  constructor(props) {
    super(props)
    this.connect = this.connect.bind(this)
    this.getRDPAgentStatus = this.getRDPAgentStatus.bind(this)
    this.requestRDPSession = this.requestRDPSession.bind(this)
    this.onProvisionChange = this.onProvisionChange.bind(this)

    const hideOption = !props.hasDefaultCredential || props.hasDefaultCredential.credential_id === -1

    if (props.credentials) {
      this.credentialOptions = [
        { name: localized("None"), id: null },
        ...(!hideOption ? [{ name: localized("Default RDP access credential"), id: "SR_RDP_ACCESS" }] : []),
        ...props.credentials,
      ]
    }

    this.state = {
      credential_id: !hideOption ? "SR_RDP_ACCESS" : null,
      auto_provision: false,
      gateway_region: "auto",
      connection_profile: null,
      connection_settings,
      rdp_check_status: null,
    }
  }

  getValueFromOptions(value, options) {
    return options.find(propEq("value", value))
  }

  getLocalizedLabel(option) {
    return prop("label", option)()
  }

  updateConnectionSettings = (value, key, preventProfileCheck) => {
    this.setState(
      state => ({
        ...state,
        connection_settings: key
          ? assoc(key, value, state.connection_settings)
          : mergeRight(state.connection_settings, value),
      }),
      () => {
        if (!preventProfileCheck) {
          const { connection_settings } = this.state
          const settings = dissoc("administrative_session", connection_settings)

          if (equals(settings, performanceSettings)) {
            this.setConnectionProfile("PERFORMANCE")
          } else if (equals(settings, standardSettings)) {
            this.setConnectionProfile("STANDARD")
          } else if (equals(settings, multimediaSettings)) {
            this.setConnectionProfile("MULTIMEDIA")
          } else {
            this.setConnectionProfile(null)
          }
        }
      },
    )
  }

  setConnectionProfile = profile => {
    this.setState({ connection_profile: profile })
  }

  setConnectionProfileAndSettings = profile => {
    this.setConnectionProfile(profile)
    profile.value && this.updateConnectionSettings(profile.settings, false, true)
  }

  async componentDidMount() {
    const nodeId = this.props.nodeId
    try {
      const { kwargs } = await window.wamp.call(nodeId, "rdp.check", [], {})
      this.setState({
        rdp_check_status: kwargs?.error,
        hideProvisionOption: kwargs?.error !== "no_rdp_service_on_target",
        rdpCheckComplete: true,
      })
    } catch (error) {
      this.setState({ hideProvisionOption: false, rdpCheckComplete: true })
    }
  }

  async getRDPAgentStatus() {
    const nodeId = this.props.nodeId
    try {
      const response = await window.wamp.call(nodeId, "status", [], {})
      return response && response.args && response.args[0] === "OK"
    } catch (error) {
      if (isReportableWampException(error)) {
        ninjaReportError(error)
      }
    }
  }

  async requestRDPSession() {
    try {
      const { credential_id, auto_provision, gateway_region, connection_settings } = this.state
      const { userType, nodeId } = this.props
      const isEndUser = userType === "END_USER"

      const request = {
        nodeId,
        credential_id: isEndUser ? null : credential_id,
        auto_provision: isEndUser ? null : auto_provision,
        gateway_region,
        connection_settings,
      }
      return await fetchJson(getUrl(userType), {
        options: {
          method: "POST",
          body: JSON.stringify(request),
        },
      })
    } catch (error) {
      ninjaReportError(error)
    }
  }

  async connect() {
    try {
      this.setState({ loading: true })

      const RDPAgentReady = await this.getRDPAgentStatus()

      const rdpStatus = this.state.rdp_check_status ? RDPErrors[this.state.rdp_check_status] : null
      if (!this.state.auto_provision && rdpStatus) {
        await ShowMessageDialog({
          icon: { icon: faExclamation, type: "critical" },
          title: localizationKey("Connection Failed"),
          message: () => (localized("Could not obtain RDP connection: ") + rdpStatus ? rdpStatus() : ""),
          buttons: [{ id: "OK", label: localizationKey("OK") }],
        })
      } else if (RDPAgentReady) {
        const response = await this.requestRDPSession()
        debugLog("RDP Sesssion response: " + JSON.stringify(response))

        if (response && response.rdpUrl) {
          setTimeout(
            () => window.open(["osx", "windows"].includes(getOs()) ? response.rdpFile : response.rdpUrl, "_parent"),
            300,
          )
        } else {
          const getError = RDPErrors[response.code]
          await ShowMessageDialog({
            icon: { icon: faExclamation, type: "critical" },
            title: localizationKey("Connection Failed"),
            message: () => (localized("Could not obtain RDP connection: ") + getError ? getError() : ""),
            buttons: [{ id: "OK", label: localizationKey("OK") }],
          })
        }
      } else {
        await ShowMessageDialog({
          icon: { icon: faExclamation, type: "critical" },
          title: localizationKey("RDP is not ready"),
          message: localizationKey("RDP is not ready for remote connections. Please try again after a minute or two."),
          buttons: [{ id: "OK", label: localizationKey("OK") }],
        })
      }
    } catch (e) {
      debugLog("error when attempting to setup RDP connection", e)
      await ShowMessageDialog({
        icon: { icon: faExclamation, type: "critical" },
        title: localizationKey("Connection Failed"),
        message: localizationKey("Could not obtain RDP connection: An unknown error occurred."),
        buttons: [{ id: "OK", label: localizationKey("OK") }],
      })
    } finally {
      this.setState({ loading: false })
      this.props.unmount()
    }
  }

  async onProvisionChange() {
    const provision = !this.state.auto_provision
    this.setState({ auto_provision: provision })
    if (provision) {
      const confirmationButtonPressed = await ShowMessageDialog({
        icon: { icon: faExclamation, type: "critical" },
        title: localizationKey("Attention"),
        message: () =>
          localized(
            "This action will enable Remote Desktop and disable Network Level Authentication (NLA). These changes are required to be able to connect through an RDP relay, and will persist after your RDP session is terminated.",
          ),
        buttons: [
          { id: "AGREE", label: localizationKey("Agree") },
          { id: "CLOSE", label: localizationKey("Close") },
        ],
      })
      if (confirmationButtonPressed !== "AGREE") {
        this.setState({ auto_provision: false })
      }
    }
  }

  render() {
    const {
      auto_provision,
      credential_id,
      connection_settings,
      gateway_region,
      loading,
      hideProvisionOption,
      connection_profile,
      rdpCheckComplete,
    } = this.state
    const { userType } = this.props
    const disableConnect = loading || !rdpCheckComplete
    const isTechnician = userType === "TECHNICIAN"

    return (
      <Portal>
        <div className="scroll-modal">
          <Modal
            overflow
            tallModal
            dialogClassName="wide-modal"
            title={localizationKey("Remote Desktop")}
            close={this.props.unmount}
            loading={disableConnect}
            save={this.connect}
            saveText={localizationKey("Connect")}
            footerLeftComponent={
              (!rdpCheckComplete && <Loading loadingText={localizationKey("Verifying device RDP settings...")} />) ||
              (loading && <Loading loadingText={localizationKey("Connecting...")} />)
            }
          >
            <div id="rdp-connect">
              {isTechnician && (
                <>
                  <section>
                    <label>{localized("Credential")}</label>
                    <div className="rdp-fields">
                      <div className="rdp-field">
                        <Select
                          isDisabled={loading}
                          value={this.credentialOptions.find(propEq("id", credential_id))}
                          options={this.credentialOptions}
                          onChange={({ id }) => this.setState({ credential_id: id })}
                          getOptionValue={prop("id")}
                          getOptionLabel={prop("name")}
                        />
                      </div>
                    </div>
                  </section>
                  <section>
                    <label />
                    <div className="rdp-fields">
                      <div className="rdp-field">
                        <Checkbox
                          disabled={loading}
                          defaultChecked={connection_settings.administrative_session}
                          onChange={() =>
                            this.updateConnectionSettings(
                              !connection_settings.administrative_session,
                              "administrative_session",
                            )
                          }
                          checkboxClass="icheckbox_square-blue"
                        />
                        {localized("Administrative session")}
                      </div>
                    </div>
                  </section>
                </>
              )}

              <section>
                <label>{localized("Gateway")}</label>
                <div className="rdp-fields">
                  <div className="rdp-field">
                    <Select
                      isDisabled={loading}
                      value={this.getValueFromOptions(gateway_region, gatewayRegions)}
                      options={gatewayRegions}
                      onChange={({ value }) => this.setState({ gateway_region: value })}
                      getOptionLabel={this.getLocalizedLabel}
                    />
                  </div>
                </div>
              </section>

              <section id="rdp-connection-settings">
                <label>{localized("Connection")}</label>
                <div className="rdp-fields">
                  <div className="rdp-field">
                    <RDPConnectionSettings
                      {...{
                        loading,
                        connection_profile,
                        connection_settings,
                        isTechnician,
                        setConnectionProfileAndSettings: this.setConnectionProfileAndSettings,
                        getValueFromOptions: this.getValueFromOptions,
                        getLocalizedLabel: this.getLocalizedLabel,
                        updateConnectionSettings: this.updateConnectionSettings,
                      }}
                    />
                  </div>
                </div>
              </section>

              {isTechnician && (
                <section>
                  <label>{localized("Provision")}</label>
                  {rdpCheckComplete && (
                    <div className="rdp-fields m-t-sm">
                      <div className="rdp-field">
                        {hideProvisionOption ? (
                          localized("Machine is ready to accept RDP connection")
                        ) : (
                          <>
                            <Checkbox
                              disabled={loading}
                              checked={auto_provision}
                              onChange={this.onProvisionChange}
                              checkboxClass="icheckbox_square-blue"
                            />
                            {localized("Configure RDP automatically")}
                            <Tooltip
                              token={localizationKey(
                                "Check this box to automatically provision this computer for RDP Access. Doing so will enable Remote Desktop, and disable Network Level Authentication (NLA). These changes are required to be able to connect through an RDP relay, and will persist after your RDP session is terminated.",
                              )}
                            />
                          </>
                        )}
                      </div>
                    </div>
                  )}
                </section>
              )}
            </div>
          </Modal>
        </div>
      </Portal>
    )
  }
}
