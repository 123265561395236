import Text from "../Text"
import { StyledEditorSidebarNavigationItem } from "../Styled"

export default function EditorNavigationItem({ token, Icon, danger, isActive, children }) {
  return (
    <StyledEditorSidebarNavigationItem {...{ danger, hasIcon: !!Icon, isActive }}>
      {Icon && <Icon color={danger ? "ninjaRed" : "darkGray"} />}
      <Text token={token} bold>
        {children}
      </Text>
    </StyledEditorSidebarNavigationItem>
  )
}
