import React, { useCallback, useEffect, useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faApple, faWindows } from "@fortawesome/free-brands-svg-icons"
import { faExclamationTriangle } from "@fortawesome/pro-solid-svg-icons"
import { faTeamviewer } from "media/icons"
import { fetchJson, getOs, localized, localizationKey } from "js/includes/common/utils"
import Modal from "js/includes/components/Modal"
import ShowMessageDialog from "js/includes/components/MessageDialog"
import { colors } from "js/includes/common/theme"
import { ninjaReportError } from "js/includes/common/utils"

function getConnectUrl(userType, nodeId) {
  switch (userType) {
    case "TECHNICIAN":
      return `/teamviewer/connect/${nodeId}`
    case "END_USER":
      return `/end-user/connect/teamviewer/${nodeId}`
    default:
      throw new Error("Invalid user type")
  }
}

function getErrorMessageFromResultCode(error) {
  switch (error.resultCode) {
    case "tv_reach_wamp_rollout_request_failure":
      return localizationKey("TeamViewer is not ready for remote connections. Please try again after a minute or two.")
    case "tv_reach_tv_offline":
    case "tv_reach_service_not_reachable":
      return localizationKey(
        "TeamViewer is not ready for remote connections. Please make sure the service is running and try again.",
      )
    case "tv_reach_api_timeout":
      return localizationKey("TeamViewer API service took too long to respond. Please try again after a minute or two.")
    case "tv_reach_api_unknown":
      return () =>
        `${localized(
          "An unknown error occurred communicating with the TeamViewer API. Please try again after a minute or two.",
        )} ${error.errorMessage}`
    default:
      return () =>
        `${localized(
          "An unknown error occurred attempting to establish TeamViewer connection. Please try again after a minute or two.",
        )} ${error.errorMessage}`
  }
}

export default function TeamViewerConnectingModal({ nodeId, userType, shouldConnect = true, unmount }) {
  const [teamviewerComponent, setComponent] = useState({
    TEAMVIEWER_CLIENT_WINDOWS: {
      downloadUrl: "https://resources.ninjarmm.com/TeamViewer/TeamViewer_Setup_v15.4.8332.exe",
    },
    TEAMVIEWER_CLIENT_MAC: {
      downloadUrl: "https://resources.ninjarmm.com/TeamViewer/TeamViewer_v15.4.4445.dmg",
    },
  })

  async function getTeamViewerAgentStatus(nodeId) {
    try {
      const response = await window.wamp.call(nodeId, "status", [], {})
      // windows agent uses worker name 'Teamviewer'
      // mac agent uses worker name 'TeamViewerNG'
      return (
        response?.kwargs?.components?.Teamviewer === "enabled" ||
        response?.kwargs?.components?.TeamViewerNG === "enabled"
      )
    } catch (error) {
      return false
    }
  }

  const connect = useCallback(async () => {
    try {
      const teamViewerAgentReady = await getTeamViewerAgentStatus(nodeId)
      if (!teamViewerAgentReady) {
        return ShowMessageDialog({
          icon: { icon: faExclamationTriangle, type: "critical" },
          title: localizationKey("TeamViewer is not ready"),
          message: localizationKey(
            "TeamViewer is not ready for remote connections. Please try again after a minute or two.",
          ),
          buttons: [{ id: "OK", label: localizationKey("OK") }],
        })
      }
      const urlResponse = await fetchJson(getConnectUrl(userType, nodeId))

      // TODO change how the missing URL is handled, look at mimicing splashtop modal
      if (!urlResponse.url) {
        throw new Error({ errorMessage: "URL generation failed" })
      }

      const newWindow = window.open(urlResponse.url, "_parent")
      newWindow.opener = null
    } catch (error) {
      return ShowMessageDialog({
        icon: { icon: faExclamationTriangle, type: "critical" },
        title: localizationKey("TeamViewer connection error"),
        message: getErrorMessageFromResultCode(error),
        buttons: [{ id: "OK", label: localizationKey("OK") }],
      })
    }
  }, [nodeId, userType])

  const setTeamviewerComponent = useCallback(async () => {
    try {
      const components = ["TEAMVIEWER_CLIENT_WINDOWS", "TEAMVIEWER_CLIENT_MAC"]
      const teamviewerClientComponent = await fetchJson("/webapp/division-components", {
        options: {
          method: "POST",
          body: JSON.stringify(components),
        },
      })
      setComponent(teamviewerClientComponent)
    } catch (error) {
      ninjaReportError(error)
    }
  }, [])

  useEffect(() => {
    shouldConnect && connect()
    setTeamviewerComponent()
  }, [shouldConnect, connect, setTeamviewerComponent])

  return (
    <Modal
      overflow
      tallModal
      dataTitle
      title={
        <>
          <FontAwesomeIcon color={colors.ninjaBlueSaturated} icon={faTeamviewer} className="fa-lg m-r-xs" />
          {localized("Teamviewer")}
        </>
      }
      close={unmount}
    >
      <div className="text-align-left">
        <div className="splashtop-instructions m-b-md">
          {localized("Attempting to launch TeamViewer connection...")}
        </div>
        <div className="splashtop-instructions m-b-md">
          {localized("To connect to a machine using the TeamViewer integration, you must have TeamViewer installed.")}
        </div>
        <div className="splashtop-download-row">
          {getOs() === "windows" ? (
            <>
              <FontAwesomeIcon size="2x" className="device-icon m-r-sm" icon={faWindows} />
              <a href={teamviewerComponent.TEAMVIEWER_CLIENT_WINDOWS.downloadUrl} download rel="noopener noreferrer">
                {localized("TeamViewer for Windows")}
              </a>
            </>
          ) : (
            getOs() === "osx" && (
              <>
                <FontAwesomeIcon size="2x" className="device-icon m-r-sm" icon={faApple} />
                <a href={teamviewerComponent.TEAMVIEWER_CLIENT_MAC.downloadUrl} download rel="noopener noreferrer">
                  {localized("TeamViewer for Mac")}
                </a>
              </>
            )
          )}
        </div>
      </div>
    </Modal>
  )
}
